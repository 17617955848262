import { ImageKey, StringKey, StorageKey, Language } from '../../constants';
import { Images, Strings } from './i18nDictionary';

export class i18n {
    public static setLang = (lang: Language) => {
        sessionStorage.setItem(StorageKey.Lang, lang);
        const body = document.body;
        body.classList.remove(`lang-${Language.EN}`);
        body.classList.remove(`lang-${Language.ES}`);
        body.classList.add(`lang-${lang}`);
    }

    public static getLang = () => (sessionStorage.getItem(StorageKey.Lang) as Language) || Language.EN;

    public static txt = (key: StringKey): string => Strings[key][i18n.getLang()] || Strings[key][Language.EN];

    public static multiLine = (key: StringKey): string =>{
        const text = i18n.txt(key);
        const lines = text.split('\r\n').map(line => line + '<br />').join('');
        return lines;
    }

    public static img = (key: ImageKey) => Images[key][i18n.getLang()] || Images[key][Language.EN];
}
