import './sharing.scss';

import { h } from 'preact';
import { RoutableProps } from 'preact-router';

import { ActM, CnstM, SrvM } from '../modules';
import { Routes } from '../routes';

const handleContinue = () => {
    ActM.SessionActions.skipSharing();
};

const handleGoBack = () => {
    ActM.AppActions.route(Routes.Help);
};

export const SharingPage = (props: RoutableProps) => {
    return (
        <section class='page-submit align-cc'>
            <h1 class='page-submit-message'>{SrvM.i18n.txt(CnstM.StringKey.SharingPopup)}</h1>
            <button class='button1 button-mar' type='button' onClick={handleContinue}>
                {SrvM.i18n.txt(CnstM.StringKey.SharingPopupYes)}
            </button>
            <button class='button2 button-mar' type='button' onClick={handleGoBack}>
                {SrvM.i18n.txt(CnstM.StringKey.SharingPopupGoBack)}
            </button>
        </section>
    );
};
