export class Routes {
    public static Help = '/help';
    public static QRcode = '/qrcode';
    public static QRcode2 = '/qrcode?type=2';
    public static Welcome = '/welcome';
    public static UserForm = '/userform';
    public static Players = '/players';
    public static LookAtScreen = '/lookatscreen';
    public static Thanks = '/thanks';
    public static Timeout = '/timeout';
    public static Submit = '/submit';
    public static Sharing = '/sharing';
}
