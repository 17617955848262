import { nanoid } from 'nanoid';

import * as ActM from '../actions';
import * as ModM from '../models';
import * as SrvM from '../services';
import { CnstM } from '../modules';
import { StorageKey, SocketEvent } from '../constants';
import { Routes } from '../routes';

const SessionStorableFields = [
    StorageKey.SessionId,
    StorageKey.ShareType,
    StorageKey.ContactInfo,
    StorageKey.TOSAgreed,
    StorageKey.TOSPrivacyAgreed,
    StorageKey.OptInAccepted,
    StorageKey.IsConnected,
    StorageKey.Players,
    StorageKey.Submit
];

const ListenSocketEvents = [
    SocketEvent.AllPlayersSelected,
    SocketEvent.CaptureComplete,
    SocketEvent.CaptureStarting,
    SocketEvent.MainMenuShown,
    SocketEvent.SessionConnected,
    SocketEvent.TimeoutDialogDisplayed,
    SocketEvent.TimeoutDialogTimedOut,
    SocketEvent.PlayersSelected,
    SocketEvent.AllPlayersSelected,
];

const registerSession = (src: Partial<ModM.ISession>) => {
    ActM.AppActions.route(Routes.QRcode);

    const session = new ModM.Session(src);
    session.sessionId = nanoid();
    saveSessionToStorage(session);

    if (SrvM.SocketService.isConnected()) {
        SrvM.SocketService.emit(SocketEvent.RegisterSession, session);
        SrvM.SocketService.on(SocketEvent.SessionConnected, () => {
            SrvM.SocketService.emit(SocketEvent.AttractScreenPressed);
        });
        SrvM.SocketService.on(SocketEvent.MainMenuShown, () => {
            ActM.AppActions.route(Routes.Players);
        });
        SrvM.SocketService.on(SocketEvent.TimeoutDialogDisplayed, () => {
            if (navigator && navigator.vibrate) navigator.vibrate(200);
        });
        SrvM.SocketService.on(SocketEvent.TimeoutDialogTimedOut, () => {
            sessionStorage.setItem(CnstM.StorageKey.Timeout, 'true');
            ActM.AppActions.route(Routes.Timeout);
        });
    }
};

const unregisterSession = () => {
    const sessionId = sessionStorage.getItem(StorageKey.SessionId);
    if (sessionId) SrvM.SocketService.emit(SocketEvent.UnregisterSession, sessionId);
    ListenSocketEvents.forEach((e) => {
        SrvM.SocketService.off(e);
    });
    removeSessionFromStorage();
};

const saveSessionToStorage = (session: ModM.ISession) =>
    SessionStorableFields.forEach((key) => {
        let value = session[key];
        if (typeof value === 'boolean') value = value ? '1' : '0';
        if (Array.isArray(value)) value = value.join(',');
        sessionStorage.setItem(key, value);
    });

const removeSessionFromStorage = () => {
    SessionStorableFields.forEach((key) => {
        sessionStorage.removeItem(key);
    });
    sessionStorage.removeItem(StorageKey.FinalImage);
};

export const SessionActions = {
    register: registerSession,
    saveSessionToStorage: saveSessionToStorage,
    unregister: unregisterSession,
    selectPlayer: (playerId: number) => {
        console.log("sessionActions: Player selected "+playerId);
        if (SrvM.SocketService.isConnected()) {
            SrvM.SocketService.emit(SocketEvent.PlayerMenuSelect, playerId);
            SrvM.SocketService.on(SocketEvent.PlayersSelected, (ids: number[]) => {
                sessionStorage.setItem(StorageKey.Players, ids.join(','));
            });
            SrvM.SocketService.on(SocketEvent.AllPlayersSelected, (ids: number[]) => {
                sessionStorage.setItem(StorageKey.Players, ids.join(','));
            });
        }
    },
    deselectPlayer: (playerId: number) => {
        if (SrvM.SocketService.isConnected()) {
            SrvM.SocketService.emit(SocketEvent.PlayerMenuDeselect, playerId);
            SrvM.SocketService.on(SocketEvent.PlayersSelected, (ids: number[]) => {
                sessionStorage.setItem(StorageKey.Players, ids.join(','));
            });
        }
    },
    submitPlayers: (playerIds: number[]) => {
        sessionStorage.setItem(StorageKey.Players, playerIds.join(','));

        if (SrvM.SocketService.isConnected()) {
            SrvM.SocketService.emit(SocketEvent.PlayerMenuSubmit);
            SrvM.SocketService.on(SocketEvent.CaptureStarting, () => {
                ActM.AppActions.route(Routes.LookAtScreen);
            });
            SrvM.SocketService.on(SocketEvent.CaptureComplete, (img: string) => {
                sessionStorage.setItem(StorageKey.FinalImage, img);
                ActM.AppActions.route(Routes.Thanks);
            });
        } else {
            ActM.AppActions.route(Routes.QRcode2);
        }
    },
    resetPlayers: () => {
        sessionStorage.removeItem(StorageKey.Players);

        if (SrvM.SocketService.isConnected()) {
            SrvM.SocketService.emit(SocketEvent.PlayerMenuReset);
        }
    },
    skipSharing: () => {
        registerSession({
            shareType: CnstM.ShareType.NoShare,
            contactInfo: CnstM.ShareType.NoShare,
            tosAgreed: false,
            tosPrivacyAgreed:false,
            optIn: false,
            playerIds: [],
        });
    },
    logSession: () => {
        // const shareType = (sessionStorage.getItem(CnstM.StorageKey.ShareType) as CnstM.ShareType) || CnstM.ShareType.NoShare;
        // let payload = null;
        // if (shareType !== CnstM.ShareType.NoShare) {
        //     const field = shareType === CnstM.ShareType.Email ? 'email' : 'phone';
        //     payload = { [field]: sessionStorage.getItem(CnstM.StorageKey.ContactInfo) };
        // }
        // window.LogRocket && window.LogRocket.identify(sessionStorage.getItem(CnstM.StorageKey.SessionId), payload);
    },
};
