import './header.scss';

import { h } from 'preact';

import { Routes } from '../routes';
import { ActM, ImgM } from '../modules';

const handleHomeClick = () => {
    ActM.AppActions.route(Routes.Help);
}

export const Header = () => {
    return (
        <header className='header-container'>
            <div onClick={handleHomeClick} className='header-data-container align-cc'>
                <img className="logo" src={ImgM.Logo} />
            </div>
        </header>
    );
};