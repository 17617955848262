import "./checkbox.scss";

import { JSX, h } from "preact";
import { useState } from "preact/hooks";
import { SrvM, CnstM } from "../modules";

interface IProps {
  name?: string;
  label?: JSX.Element | string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => void;
}

export const Checkbox = (props: IProps) => {
  //const [checked, setChecked] = useState(props.checked);
  const handleChange = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
    //setChecked(!checked);
    if (props.onChange) props.onChange(e);
  };
  return (
    <label
      class={`checkbox ${props.checked ? "checkbox-checked" : ""} ${
        props.disabled ? "checkbox-disabled" : ""
      }`}
    >
      <input
        type="checkbox"
        name={props.name}
        // checked={checked}
        checked={props.checked}
        onChange={handleChange}
        disabled={props.disabled}
      />
      {props.label && (
        <span class="checkbox-label">
          {props.label}{" "}
          {props.required ? (
            <b> {SrvM.i18n.txt(CnstM.StringKey.RequiredText)}</b>
          ) : (
            ""
          )}
        </span>
      )}
    </label>
  );
};

// {props.required ? <b>* </b> : ""}