import io from 'socket.io-client';

import { SocketEvent } from '../constants';
import { AppActions } from '../actions';

export class SocketService {
    private static instance: SocketService;
    private static socket: SocketIOClient.Socket;
    private static onConnect?: Function;
    private static onDisconnect?: Function;

    constructor() {
        if (typeof SocketService.instance === 'undefined') {
            SocketService.instance = this;
        }
        return SocketService.instance;
    }

    public static connect(uri?: string) {
        SocketService.socket = io(uri);

        SocketService.socket.on('connect', () => {
            AppActions.goOnline();
            if (this.onConnect) {
                this.onConnect();
            }
            console.log(`Socket Service: connected to socket ${this.socket.id}`);
        });

        SocketService.socket.on('reconnect', () => {
            AppActions.goOnline();
            if (this.onConnect) {
                this.onConnect();
            }
        });

        SocketService.socket.on('reconnect_attempt', (attempt: number) => {
            console.log(`Socket Service: reconnection attempt to socket ${this.socket.id} (${attempt})`);
            if (attempt === 3) {
                AppActions.goOffline();
                if (this.onDisconnect) {
                    this.onDisconnect();
                }
                SocketService.socket.disconnect();
            }
        });

        SocketService.socket.on('disconnect', () => {
            console.log(`Socket Service: disconnected`);
            AppActions.goOffline();
            if (this.onDisconnect) {
                this.onDisconnect();
            }
        });
    }

    public static disconnect() {
        if (SocketService.socket) {
            AppActions.goOffline();
            SocketService.socket.disconnect();
        }
    }

    public static emit(event: SocketEvent, payload?: any) {
        if (SocketService.socket) {
            console.log(`SocketService: emit ${event} with ${JSON.stringify(payload, null, 2)}`);
            SocketService.socket.emit(event, payload);
        }
    }

    public static on(event: SocketEvent, callback?: Function) {
        if (event === SocketEvent.Disconnect) {
            SocketService.onDisconnect = callback;
        }
        else if (event === SocketEvent.Connect) {
            SocketService.onConnect = callback;
        } else if (SocketService.socket) {
            console.log(`SocketService: listen for ${event}`);
            function handleCallProxy() {
                if (event === SocketEvent.CaptureComplete) {
                    const image: string = (typeof arguments[0] === "object" && Array.isArray(arguments[0])) ? arguments[0][0] : arguments[0];
                    console.log(`SocketService: received ${event} with ${arguments.length} arguments and the image is ${image.length}b long`);
                } else {
                    console.log(`SocketService: received ${event} with ${JSON.stringify(arguments, null, 2)}`);
                }
                callback.apply(this, arguments);
            }
            SocketService.socket.on(event, handleCallProxy);
        };
    }

    public static off(event: SocketEvent) {
        if (SocketService.socket) {
            console.log(`SocketService: unlisten from ${event}`);
            SocketService.socket.off(event);
        };
    }

    public static close() {
        SocketService.socket.close();
    }

    public static isConnected = () => SocketService.socket && SocketService.socket.connected;

}
