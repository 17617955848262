import './timeout.scss';

import { h } from 'preact';
import { RoutableProps } from 'preact-router';

import { ActM, CnstM, SrvM } from '../modules';
import { Routes } from '../routes';

const handleContinue = () => {
    ActM.AppActions.route(Routes.Help);
};

export const TimeoutPage = (props: RoutableProps) => {
    if (!sessionStorage.getItem(CnstM.StorageKey.Timeout)) {
        ActM.AppActions.route(Routes.Help);
        return null;
    }
    return (
        <section class='page-timeout align-cc'>
            <h1 class='page-timeout-message'>{SrvM.i18n.txt(CnstM.StringKey.Timeout)}</h1>
            <button class='button3' type='button' onClick={handleContinue}>{SrvM.i18n.txt(CnstM.StringKey.Continue)}</button>
        </section>
    );
};
