import Step1 from '../assets/img/step1.svg';
import Step2 from '../assets/img/step2.svg';
import Step3 from '../assets/img/step3.svg';

import Player1 from '../assets/img/prescott.png';
import Player2 from '../assets/img/zeke.png';
import Player3 from '../assets/img/jake.png';
import Player4 from '../assets/img/lawrence.png';
import Player5 from '../assets/img/lamb.png';
import Player6 from '../assets/img/micah.png';
import Player7 from '../assets/img/diggs.png';
import Player8 from '../assets/img/smith.png';
import Player9 from '../assets/img/gilmore.png';

import Logo from '../assets/img/logo.png';

const Steps = [Step1, Step2, Step3];
const Players = [Player1, Player2, Player3, Player4, Player5, Player6, Player7, Player8, Player9];

export { Steps, Players, Logo };
