import { CnstM } from '../modules';

export interface ISession {
    contactInfo: string;
    tosAgreed: boolean;
    tosPrivacyAgreed: boolean;
    optIn: boolean;
    sessionId?: string;
    playerIds?: number[];
    shareType?: CnstM.ShareType;
    isConnected?: boolean;
    submit?: string;
}

export class Session implements ISession {
    public shareType: CnstM.ShareType;
    public contactInfo: string;
    public tosAgreed: boolean;
    public tosPrivacyAgreed: boolean;
    public optIn: boolean;
    public sessionId?: string;
    public playerIds?: number[];
    public isConnected?: boolean;
    public submit?: string;

    constructor(src?: Partial<ISession>) {
        this.sessionId = !!src && src.sessionId;
        this.shareType = !!src && src.shareType;
        this.contactInfo = !!src ? src.contactInfo : '';
        this.tosAgreed = !!src && src.tosAgreed;
        this.tosPrivacyAgreed = !!src && src.tosPrivacyAgreed;
        this.optIn = !!src && src.optIn;
        this.playerIds = !!src ? src.playerIds : [];
        this.isConnected = !!src && src.isConnected;
        this.submit = !!src ? src.submit : '';        
    }
}
