import { StorageKey, Mode } from '../constants';

//ADI: Eventually add a StorageKey.TOSPrivacyAgreed to share that key value with unity
const QRStringFields = [StorageKey.SessionId, StorageKey.Lang, StorageKey.ShareType, StorageKey.ContactInfo, StorageKey.TOSPrivacyAgreed, StorageKey.OptInAccepted, StorageKey.TOSAgreed];


export class Utils
{
    public static getStringForQR = () =>
    {
        
        let result = QRStringFields.map((key) => sessionStorage.getItem(key)).join('|');
        const players = sessionStorage.getItem(StorageKey.Players);
        if (players) result += `|${players}`;
        console.log("QR Result "+result);
        return result;
    };

    public static isOnline = () => sessionStorage.getItem(StorageKey.Mode) !== Mode.Offline;
}
