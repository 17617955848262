// en
import Footer_EN from '../../../assets/img/en/newFooterWhite.png';

// es
import Footer_ES from '../../../assets/img/en/newFooterWhite.png';

import { ImageKey, StringKey } from '../../constants';

export const Strings = {
    [StringKey.ByCheckingTheFollowing]: { en: 'By checking the following...', es: 'Al verificar lo siguiente...' },
    [StringKey.CheckEmailOrText]: {
        en: 'Check your email inbox or text messages for your photo and GIF.',
        es: 'Revisa tu buzón de correo electrónico o mensajes de texto para ver tu foto y GIF',
    },
    [StringKey.Continue]: { en: 'Continue', es: 'Continúa' },
    [StringKey.ControlScreen]: { en: 'Select Players on Your Phone', es: 'Selecciona jugadores en tu teléfono' },
    [StringKey.SelectPlayersTitle]: { en: 'Select', es: 'Selecciona' },
    [StringKey.SelectPlayersSubTitle]: { en: 'Five Players', es: '5 jugadores' },

    [StringKey.Email]: { en: 'Email', es: 'Correo electrónico' },
    [StringKey.English]: { en: 'English' },
    [StringKey.EnterMobile]: { en: 'Please enter your mobile number', es: 'Por favor ingresa tu número móvil' },
    [StringKey.EnterEmail]: { en: 'Please enter your email', es: 'Por favor ingresa tu correo electrónico' },
    [StringKey.EnterInfo]: { en: 'Enter Your Info', es: 'Ingresa tu información' },
    [StringKey.FixIt]: { en: 'Fix it', es: 'Arréglalo' },
    [StringKey.GetStarted]: { en: 'Let\'s Get Started', es: 'Comencemos' },
    [StringKey.GetStartedGuidance]: { en: 'Control the Screen With Your Phone', es: 'Controla la pantalla con tu teléfono' },
    [StringKey.HoldQR]: { en: 'Show Your QR to the Camera', es: 'Demuestra el código QR a la cámara' },
    [StringKey.HoldQRTitle]: { en: 'Please hold', es: 'Por favor' },    
    [StringKey.HoldQRGuidance]: { en: 'your phone to the column camera', es: 'escanea el código qr en el monitor' },    
    [StringKey.HowToReceive]: {
        en: 'How do you want to receive your Pose With the Pros photo and GIF?',
        es: '¿Cómo deseas recibir tu foto y GIF de "Pose with the Pros"?',
    },
    [StringKey.OptIn]: {
        en: 'I would like to receive promotional emails from AT&T (optional)',
        es: 'Me gustaría recibir correos electrónicos promocionales de AT&T (opcional)',
    },
    [StringKey.Reset]: { en: 'Reset', es: 'Comenzar' },
    [StringKey.SkipSharing]: { en: 'Skip sharing', es: 'Salta "compartir"' },
    [StringKey.Spanish]: { en: 'Español' },
    [StringKey.Step]: { en: 'Step', es: 'Paso' },
    [StringKey.Submit]: { en: 'Submit', es: 'Enviar' },
    [StringKey.Text]: { en: 'Text', es: 'Texto' },
    [StringKey.Thanks]: { en: 'Thanks for posing!', es: '¡Gracias por posar!' },
    [StringKey.Timeout]: {
        en: 'Your session has timed out or become disconnected. Press continue to re-start.',
        es: 'Tu sesión ha terminado o se ha desconectado. Oprime "continuar" para reiniciar.',
    },
    [StringKey.TOS]: {
        en: 'I am 18 years of age or older and allow AT&T to collect my data for this activation',
        es: 'Tengo 18 años o más y permito que AT&T recopile mis datos para esta activación',
    },
    [StringKey.RequiredText]: {
        en: '(required)',
        es: '(requerido)',
    },
    [StringKey.Welcome]: { en: 'Welcome', es: 'Bienvenido' },
    [StringKey.LookAtBigScreen]: { en: 'Look at the big screen, not me', es: 'Mira a la pantalla grande, no a mí' },
    [StringKey.EmailPopup]: { en: 'Please enter a valid email address', es: 'Por favor ingresa un correo electrónico válido' },
    [StringKey.PhonePopup]: { en: 'Please enter a valid phone number', es: 'Por favor ingresa un número de teléfono válido' },
    [StringKey.PrivacyPolicy]: { en: 'Privacy Policy', es: 'Norma de privacidad' },
    [StringKey.PrivacyPolicyLink]: { en: 'https://about.att.com/csr/home/privacy/full_privacy_policy.html', es: 'https://www.att.com/es-us/sdabout/csr/home/privacy/full_privacy_policy.html' },
    [StringKey.CheckboxPopup]: { en: 'Please check the required box.', es: 'Por favor marca la casilla requerida.' },
    [StringKey.SharingPopup]: { en: 'Are you sure you want to skip receiving your photo?', es: '¿Estás seguro de que no quieres recibir tu foto?' },
    [StringKey.SharingPopupYes]: { en: 'Yes', es: 'Sí' },
    [StringKey.SharingPopupGoBack]: { en: 'Go Back', es: 'Regresar' },
    [StringKey.TOS_Privacy]: {
        en: 'By marking the box below, I agree and consent that AT&T, and its affiliated companies, as well as third parties acting on AT&T’s behalf, may process personal data from or about me as outlined in the <a href="{link_en}" target="_blank">AT&T Privacy Notice</a> necessary to register me and facilitate my participation at the event.',
        es: 'Al marcar la casilla a continuación, acepto y consiento que AT&T y sus empresas afiliadas, así como terceros que actúen en nombre de AT&T, puedan procesar datos personales de o sobre mí según lo descrito en la <a href="{link_es}" target="_blank">Norma de privacidad de AT&T</a> necesaria para registrarme y facilitar mi participación en el evento.',
    },
    [StringKey.AgeGatePopupBoxText]:{ en: 'Are you at least<br>18 years of age?', es: '¿Tienes al menos 18 años de edad?' },
    [StringKey.AgeGateNo]: { en: 'No', es: 'No' }
};

export const Images = {
    [ImageKey.Footer]: { en: Footer_EN, es: Footer_ES },
};
