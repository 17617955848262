import "./players.scss";

import { h } from "preact";
import { RoutableProps } from "preact-router";

import { CnstM, ActM, ImgM, SrvM } from "../modules";
import { useState } from "preact/hooks";

const Players: IPlayer[] = [
  { id: 1, firstName: "DAK", lastName: "PRESCOTT", photo: ImgM.Players[0] },
  { id: 2, firstName: "EZEKIEL", lastName: "ELLIOTT", photo: ImgM.Players[1] },
  { id: 3, firstName: "JAKE", lastName: "FERGUSON", photo: ImgM.Players[2] },
  {
    id: 4,
    firstName: "DEMARCUS",
    lastName: "LAWRENCE",
    photo: ImgM.Players[3],
  },
  { id: 5, firstName: "CEEDEE", lastName: "LAMB", photo: ImgM.Players[4] },
  { id: 6, firstName: "MICAH", lastName: "PARSONS", photo: ImgM.Players[5] },
  { id: 7, firstName: "TREVON", lastName: "DIGGS", photo: ImgM.Players[6] },
  { id: 8, firstName: "TYLER", lastName: "SMITH", photo: ImgM.Players[7] },
  //{ id: 9, firstName: 'STEPHON', lastName: 'GILMORE', photo: ImgM.Players[8] }, //Remove this player
];

interface IPlayer {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
}

export const PlayersPage = (props: RoutableProps) => {
  const [selectedPlayerIds, setPlayerIds] = useState([] as number[]);
  const isSubmitEnabled =
    selectedPlayerIds.length === CnstM.Constants.MaxPlayersSelected;

  const handleSelectPlayer = (id: number) => {
    const ids = [...selectedPlayerIds];
    if (ids.some((p) => p === id)) {
      const index = ids.findIndex((p) => p === id);
      if (index > -1) {
        ids.splice(index, 1);
        ActM.SessionActions.deselectPlayer(id);
      }
    } else if (ids.length < CnstM.Constants.MaxPlayersSelected) {
      ids.push(id);
      ActM.SessionActions.selectPlayer(id);
    }

    setPlayerIds(ids);
  };

  const handleSubmit = () => {
    ActM.SessionActions.submitPlayers(selectedPlayerIds);
  };

  const handleReset = () => {
    setPlayerIds([]);
    ActM.SessionActions.resetPlayers();
  };

  return (
    <div>
      <div className="page-select">
        {SrvM.i18n.txt(CnstM.StringKey.SelectPlayersTitle)}
      </div>
      <div className="page-five-players">
        {SrvM.i18n.txt(CnstM.StringKey.SelectPlayersSubTitle)}
      </div>
      <div className="players-list">
        {Players.map((player) => {
          const isSelected = selectedPlayerIds.some((p) => p === player.id);
          return (
            <div
              key={player.id}
              className={`player ${isSelected ? "player-selected" : ""}`}
              onClick={() => handleSelectPlayer(player.id)}
            >
              <div className="player-img-wrapper align-cc">
                <img className="player-img" src={player.photo} />
              </div>
              <div className="player-first-name">{player.firstName}</div>
              <div className="player-last-name">{player.lastName}</div>
            </div>
          );
        })}
      </div>
      <button className="button1 button-mar" onClick={() => handleReset()}>
        {SrvM.i18n.txt(CnstM.StringKey.Reset)}
      </button>
      <button
        className="button2 button-mar"
        onClick={() => handleSubmit()}
        disabled={!isSubmitEnabled}
      >
        {SrvM.i18n.txt(CnstM.StringKey.Submit)}
      </button>
    </div>
  );
};
