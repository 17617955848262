import './thanks.scss';

import { h, Component } from 'preact';
import { SrvM, CnstM, ActM } from '../modules';
import { StorageKey, Constants } from '../constants';
import { Routes } from '../routes';

export class ThanksPage extends Component<any, any> {
    state = {
        isOnline: SrvM.Utils.isOnline(), 
        shareType: sessionStorage.getItem(StorageKey.ShareType)
    }

    img: string;

    constructor() {
        super();

        this.img = sessionStorage.getItem(StorageKey.FinalImage);
    }

    componentDidMount() {
        setTimeout(() => {
            ActM.AppActions.route(Routes.Help);
        }, Constants.RedirectTimeout);
    }

    public render() {

        //This is based of the share value the user sends over, if there is no data that noshare will == false
        const noShareUsed = (
            this.state.shareType === null ? false : 
            this.state.shareType === 'noshare' ? true : false
        );
        console.log(this.state.shareType + noShareUsed);

        return (
            <div className='thanks-page-container'>
                <span className='thanks-header'>{SrvM.i18n.txt(CnstM.StringKey.Thanks)}</span>
                <span className='thanks-details'>{noShareUsed ? '' : SrvM.i18n.txt(CnstM.StringKey.CheckEmailOrText)}</span>
                {this.state.isOnline ? <img className='final-image' src={this.img}></img> : '' }
            </div>
        );
    }
};
