export class Constants {
    public static ApiUrl = process.env.BASE_API_URL;
    public static IsDev = process.env.NODE_ENV !== 'production';
    public static ReconnectionAttempts = 3;
    public static Validation = {
        EmailRegEx: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
        PhoneRegEx: /[0-9]{10}/,
    };
    public static QR = {
        PixelsPerModule: 6,
    };
    public static MaxPlayersSelected = 5;
    public static RedirectTimeout = 5000;
    public static OfflineTimeout = 20000;
    public static LookAtTimeout = 50000;
}

export enum SocketEvent {
    AllPlayersSelected = 'ALL_PLAYERS_SELECTED',
    AttractScreenPressed = 'ATTRACT_SCREEN_PRESSED',
    CaptureComplete = 'CAPTURE_COMPLETE',
    CaptureStarting = 'CAPTURE_STARTING',
    MainMenuShown = 'MAIN_MENU_SHOWN',
    PlayerMenuDeselect = 'PLAYER_MENU_DESELECT',
    PlayerMenuReset = 'PLAYER_MENU_RESET',
    PlayerMenuSelect = 'PLAYER_MENU_SELECT',
    PlayerMenuSubmit = 'PLAYER_MENU_SUBMIT',
    PlayersSelected = 'PLAYERS_SELECTED ',
    RegisterSession = 'REGISTER_SESSION',
    SessionConnected = 'SESSION_CONNECTED',
    TimeoutDialogDisplayed = 'TIME_OUT_DIALOG_DISPLAYED',
    TimeoutDialogTimedOut = 'TIME_OUT_DIALOG_TIMED_OUT',
    UnregisterSession = 'UNREGISTER_SESSION',
    Disconnect = 'SYSTEM_DISCONNECT',
    Connect = 'SYSTEM_CONNECT',
}

export enum Language {
    EN = 'en',
    ES = 'es',
}

export enum StorageKey {
    SessionId = 'sessionId',
    Lang = 'lang',
    ShareType = 'shareType',
    ContactInfo = 'contactInfo',
    TOSAgreed = 'tosAgreed',
    OptInAccepted = 'optIn',
    TOSPrivacyAgreed='tosPrivacyAgreed',
    IsConnected = 'isConnected',
    Players = 'playerIds',
    Mode = 'mode',
    FinalImage = 'finalImage',
    Timeout = 'timeout',
    Submit = 'submit',
    AgeConfirmed = 'ageConfirmed'
}

export enum StringKey {
    ByCheckingTheFollowing,
    CheckEmailOrText,
    Continue,
    ControlScreen,
    Email,
    English,
    EnterEmail,
    EnterInfo,
    EnterMobile,
    FixIt,
    GetStarted,
    GetStartedGuidance,
    HoldQR,
    HoldQRTitle,
    HoldQRGuidance,
    HowToReceive,
    LookAtBigScreen,
    OptIn,
    Reset,
    SelectPlayersTitle,
    SelectPlayersSubTitle,
    SkipSharing,
    Spanish,
    Step,
    Submit,
    Text,
    Timeout,
    Thanks,
    TOS,
    TOS_Privacy,
    Welcome,
    EmailPopup,
    PhonePopup,
    PrivacyPolicy,
    PrivacyPolicyLink,
    CheckboxPopup,
    SharingPopup,
    SharingPopupYes,
    SharingPopupGoBack,
    RequiredText,
    AgeGatePopupBoxText,
    AgeGateNo
}

export enum ImageKey {
    Footer = 'newFooterWhite.png',
}

export enum ShareType {
    Phone = 'phone',
    Email = 'email',
    NoShare = 'noshare'
}

export enum Mode {
    Offline = 'offline',
    Online = 'online',
}
