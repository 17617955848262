import { route } from 'preact-router';

import * as SrvM from '../services';
import { SessionActions } from '../actions/sessionActions';
import { Constants, Language, StorageKey, Mode } from '../constants';
import { Routes } from '../routes';

const routeTo = (path: string) => {
    console.log(path);
    document.documentElement.style.setProperty('--page-opacity', '0');
    setTimeout(() => {
        route(path, true);
        document.documentElement.style.setProperty('--page-opacity', '1');
        gtag('event', 'page_view', { page_path: path });
    }, 300);
};

export const AppActions = {
    init: () => {
        sessionStorage.clear();
        const spanish = navigator.language && navigator.language.toLowerCase().startsWith('es-') || window.location.href.indexOf('?es') > -1
        const defaultLang = spanish ? Language.ES : Language.EN;
        SrvM.i18n.setLang(defaultLang);
        SrvM.SocketService.connect(Constants.ApiUrl);
        routeTo(Routes.Help);
        gtag('event','Touchless Started')
    },
    destroy: () => {
        SessionActions.unregister();
        SrvM.SocketService.close();
        sessionStorage.clear();
    },
    goOffline: () => sessionStorage.setItem(StorageKey.Mode, Mode.Offline),
    goOnline: () => sessionStorage.setItem(StorageKey.Mode, Mode.Online),
    route: routeTo,
};
