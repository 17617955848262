import './qrcode.scss';

import { h, Component } from 'preact';
import { QArrr } from 'qarrr';
import { RouterProps } from 'preact-router';

import { Routes } from '../routes';
import { StringKey, StorageKey, SocketEvent } from '../constants';
import { SrvM, CnstM, ActM } from '../modules';
import { SocketService } from '../services';

interface IState {
    isSubmitButtonVisible: boolean;
}
interface IProps extends RouterProps { }

export class QRCodePage extends Component<IProps, IState> {
    private timeout = null;
    state = {
        isSubmitButtonVisible: false,
    };
    public componentDidMount() {
        this.setState({ isSubmitButtonVisible: !SocketService.isConnected() })

        const qrCode = SrvM.Utils.getStringForQR();
        const q = new QArrr();
        const qrcd = q.create(qrCode, {
            pixelsPerModule: CnstM.Constants.QR.PixelsPerModule,
        });
        const canvas = q.toCanvas(qrcd);
        const qrcodeElement = document.getElementById('qrcode');
        qrcodeElement.appendChild(canvas);
        ActM.SessionActions.logSession();
        SocketService.on(SocketEvent.Disconnect, () => {
            this.setState({ isSubmitButtonVisible: true })
        })

    }

    public componentWillUnmount() {
        clearTimeout(this.timeout);
        SocketService.on(SocketEvent.Disconnect, null);
    }

    public render() {
        const isSecondQR = (this.props.url === Routes.QRcode2);
        return (
            <div class='page-qr'>
                <div className='page-title-qr'>{SrvM.i18n.txt(StringKey.HoldQRTitle)}</div>
                <div className='page-sub-title-qr'>{SrvM.i18n.txt(StringKey.HoldQRGuidance)}</div>
                <div id='qrcode' className='qr-code' onClick={this.handleQRClick} />
                {!isSecondQR && this.state.isSubmitButtonVisible && (
                    <button className='button1 button-margin' onClick={this.handleContinue}>
                        {SrvM.i18n.txt(StringKey.Continue)}
                    </button>
                )}
            </div>
        );
    }

    private handleContinue = () => {
        SrvM.SocketService.disconnect();
        ActM.AppActions.route(sessionStorage.getItem(StorageKey.Players) ? Routes.LookAtScreen : Routes.Players);
    };

    private handleQRClick = () => {
        const el = document.createElement('textarea');
        el.value = sessionStorage.getItem(StorageKey.SessionId);
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
}
