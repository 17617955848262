import { h, Component } from "preact";
import "./popup.scss";
import { SrvM, CnstM } from "../modules";

interface PopupProps {
  onClose: (isOfAge: boolean) => void;
}

interface PopupState {
  isOfAge: boolean | null;
}

export class AgeConfirmationPopup extends Component<PopupProps, PopupState> {
  constructor(props: PopupProps) {
    super(props);
    this.state = {
      isOfAge: null, // Default to null, meaning no selection
    };
  }

  handleRadioChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    this.setState({ isOfAge: target.value === "yes" });
  };

  render() {
    return (
      <div className="popup-container">
        <div className="popup-wrapper">
          <div className="popup-content">
            <h2 style={{ color: "white" }}
            dangerouslySetInnerHTML={{ __html: SrvM.i18n.txt(CnstM.StringKey.AgeGatePopupBoxText) }}
            />
            <div className="popup-buttons">
              <label>
                <input
                  type="radio"
                  name="age"
                  value="yes"
                  checked={this.state.isOfAge === true}
                  onChange={this.handleRadioChange}
                />
                {SrvM.i18n.txt(CnstM.StringKey.SharingPopupYes)}
              </label>
              <label>
                <input
                  type="radio"
                  name="age"
                  value="no"
                  checked={this.state.isOfAge === false}
                  onChange={this.handleRadioChange}
                />
                {SrvM.i18n.txt(CnstM.StringKey.AgeGateNo)}
              </label>
            </div>
            <button
              className="continue-button button3"
              onClick={() => {
                if (this.state.isOfAge !== null) {
                  this.props.onClose(this.state.isOfAge);
                }
              }}
              disabled={this.state.isOfAge === null} // Disable button if no option selected
            >
              {SrvM.i18n.txt(CnstM.StringKey.Continue)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
