import './lookAtScreen.scss';

import { h, Component } from 'preact';
import { RoutableProps } from 'preact-router';

import { SrvM, CnstM, ActM } from '../modules';
import { StringKey } from '../constants';
import { Routes } from '../routes';

interface IState {
    isSubmitButtonVisible: boolean;
}

export class LookAtScreenPage extends Component<RoutableProps, IState> {
    private timeout = null;
    state = {
        isSubmitButtonVisible: false,
    };

    componentDidMount() {
        this.timeout = setTimeout(() => {
            if (!SrvM.Utils.isOnline()) this.setState({ isSubmitButtonVisible: true });
        }, CnstM.Constants.LookAtTimeout);
    }

    public componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        return (
            <div className='lookAtScreen-page-container align-cc'>
                <span className='lookAtScreen-text'>{SrvM.i18n.txt(CnstM.StringKey.LookAtBigScreen)}</span>
                {(!SrvM.Utils.isOnline() || this.state.isSubmitButtonVisible) && (
                    <button className='button1 button-margin' onClick={this.handleContinue}>
                        {SrvM.i18n.txt(StringKey.Continue)}
                    </button>
                )}
            </div>
        );
    }

    private handleContinue = () => {
        ActM.AppActions.route(Routes.Thanks);
    };
}
