import './submit.scss';

import { h } from 'preact';
import { RoutableProps } from 'preact-router';

import { ActM, CnstM, SrvM } from '../modules';
import { Routes } from '../routes';
import { StorageKey } from '../constants'

const handleContinue = () => {
    sessionStorage.removeItem(StorageKey.Submit);
    ActM.AppActions.route(Routes.UserForm);
};

export const SubmitPage = (props: RoutableProps) => {
    return (
        <section class='page-submit align-cc'>
            <h1 class='page-submit-message'>{sessionStorage.getItem(CnstM.StorageKey.Submit)}</h1>
            <button class='button3' type='button' onClick={handleContinue}>{SrvM.i18n.txt(CnstM.StringKey.FixIt)}</button>
        </section>
    );
};
