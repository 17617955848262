import { h, Component, Fragment } from 'preact';
import { Router } from 'preact-router';
import { createHashHistory } from 'history';

import { QRCodePage, HelpPage, WelcomePage, UserFormPage, PlayersPage, LookAtScreenPage, ThanksPage, TimeoutPage, SubmitPage, SharingPage } from './pages';
import { Header, Footer } from './components';
import { ActM, SrvM, CnstM } from './modules';
import { Routes } from './routes';

const history = createHashHistory();

export class App extends Component<any, any> {
    private oHeight: number;
    private oWidth: number;

    state = {
        isOnline: SrvM.Utils.isOnline(),
    };

    public componentDidMount() {
        this.oHeight = window.innerHeight;
        this.oWidth = window.innerWidth;
        this.addEventListeners();
        this.updateViewPortHeight();
        ActM.AppActions.init();
    }

    public componentWillUnmount() {
        this.handleUnmount();
        this.removeEventListeners();
    }

    public render() {
        return (
            <Fragment>
                <Header />
                <main class='align-cc'>
                    <Router history={history}>
                        <HelpPage path={Routes.Help} default />
                        <UserFormPage path={Routes.UserForm} />
                        <QRCodePage path={Routes.QRcode} />
                        <PlayersPage path={Routes.Players} />
                        <LookAtScreenPage path={Routes.LookAtScreen} />
                        <ThanksPage path={Routes.Thanks} />
                        <TimeoutPage path={Routes.Timeout} />
                        <SubmitPage path={Routes.Submit} />
                        <SharingPage path={Routes.Sharing} />
                    </Router>
                </main>
                <Footer />
            </Fragment>
        );
    }

    private handleUnmount = () => {
        ActM.AppActions.destroy();
    };

    private handleSwitchMode = () => {
        if (this.state.isOnline) SrvM.SocketService.disconnect();
        else SrvM.SocketService.connect(CnstM.Constants.ApiUrl);
        this.setState({ isOnline: !this.state.isOnline });
    };

    private addEventListeners = () => {
        document.addEventListener('touchstart', () => null, false);
        window.addEventListener('resize', this.updateViewPortHeight);
        window.addEventListener('beforeunload', this.handleUnmount);
    };

    private removeEventListeners = () => {
        document.removeEventListener('touchstart', () => null);
        window.removeEventListener('resize', this.updateViewPortHeight);
        window.removeEventListener('beforeunload', this.handleUnmount);
    };

    private updateViewPortHeight = () => {
        const vh = window.innerHeight * 0.01;
        if (this.oWidth !== window.innerWidth) {
            this.oHeight = window.innerHeight;
            this.oWidth = window.innerWidth;
        }
        const fontSizeVh = this.oHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--font-size', `${fontSizeVh}px`);
    };
}
